import { useCallback, useState, useMemo } from "react"
import { useDataGrid } from "./context/DataGridContext"
import DataGridHeaderCellFilterOptions from "./DataGridHeaderCellFilterOptions"
import { ReactComponent as Filter } from "./assets/filter.svg"

export default function DataGridHeaderCellFilter({ column }) {
  const [isActive, setIsActive] = useState(false)

  const onClick = useCallback(event => {
    event.stopPropagation()
    if (event.target.tagName !== "DIV") return
    setIsActive(!isActive)
  }, [isActive])

  const { filters } = useDataGrid()

  const onMouseLeave = useCallback(() => {
    setTimeout(() => setIsActive(false), 100)
  }, [])

  const activeFiltering = useMemo(() => filters.find(filter => filter.key === column.key), [filters, column.key])
  
  return (
    <div
      title="Filter column" 
      className={activeFiltering ? "data-grid-header-cell-filter dg-hc-b-filtered" : "data-grid-header-cell-filter dg-hc-b" }
      onClick={onClick}>
      <Filter height={12} width={12} />
      { 
        isActive && <DataGridHeaderCellFilterOptions column={column} onMouseLeave={onMouseLeave} />
      }
    </div>
  )
}
