import DataGridCell from "./DataGridCell";
import { useDataGrid } from "./context/DataGridContext";
import DataGridEditableCell from "./DataGridEditableCell";
import useWebSocket from "react-use-websocket";
import { useState, useEffect } from "react";

export default function DataGridRow({ record, position, colStart, colEnd }) {
  const { lastJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, {
    share: true,
  });
  const [lastFocusMessage, setLastFocusMessage] = useState();
  const [isActive, setIsActive] = useState(
    lastFocusMessage?.focus?.map((session) => session.focus).includes(record.id)
  );
  const [color, setColor] = useState("lightblue");
  const { columns } = useDataGrid();

  useEffect(() => {
    if (lastJsonMessage?.focus) {
      setLastFocusMessage(lastJsonMessage);
      const focusedRecords = lastJsonMessage.focus.map(
        (session) => session.focus
      );
      setIsActive(focusedRecords.includes(record.id));
      let freq = focusedRecords.reduce(
        (a, v) => (v === record.id ? a + 1 : a),
        0
      );
      setColor(freq > 1 ? "yellow" : "lightblue");
    }
  }, [lastJsonMessage, record.id]);

  return (
    <div
      style={{
        gridRowStart: position,
        gridTemplateColumns: "var(--dg-col-width)",
        backgroundColor: workedBackground(record),
      }}
      className="data-grid-row"
    >
      {getViewportColumns(columns, record, colStart, colEnd, isActive, color)}
    </div>
  );
}

function getViewportColumns(
  columns,
  record,
  startIdx,
  endIdx,
  isActive,
  color
) {
  const viewportColumns = [];
  for (let i = startIdx; i <= endIdx; i++) {
    const column = columns[i];
    if (!column.frozen)
      viewportColumns.push(
        column.editable ? (
          <DataGridEditableCell
            key={column.key}
            record={record}
            column={column}
            columnIdx={i}
            isActive={isActive}
            color={color}
          />
        ) : (
          <DataGridCell
            key={column.key}
            record={record}
            column={column}
            columnIdx={i}
            isActive={isActive}
            color={color}
          />
        )
      );
  }
  const frozenColumns = columns
    .filter((column) => column.frozen)
    .map((column, i) => {
      return column.editable ? (
        <DataGridEditableCell
          key={column.key}
          record={record}
          column={column}
          columnIdx={i}
          isActive={isActive}
        />
      ) : (
        <DataGridCell
          key={column.key}
          record={record}
          column={column}
          columnIdx={i}
          isActive={isActive}
        />
      );
    });
  return [...frozenColumns, ...viewportColumns];
}

function workedBackground(record) {
  if (
    record?.BRANCH_ACTION ||
    record?.BRANCH_COMMENT ||
    record?.SEND_COMMENT_TO
  ) {
    return "lightblue";
  } else {
    return "#eee";
  }
}
